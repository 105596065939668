import React from "react";

const LandIcons = ({ enterprise }) => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          {enterprise?.caracteristicas.length > 0 && (
            <div
              className="d-flex align-items-center col-12 px-0 justify-content-around"
              style={{ flexWrap: "wrap" }}
            >
              {enterprise?.caracteristicas.map((caracteristica) => (
                <div
                  key={caracteristica.id}
                  className="d-inline-block border15 d-flex flex-column justify-content-center align-items-center m-1 icons1"
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "38px", // Tamanho do ícone
                      color: "#4B40C5", // Cor do ícone
                      fontWeight: 300, // Peso da fonte
                      fontVariationSettings: '"GRAD" 0, "OPSZ" 40', // Configurações de variação da fonte
                    }}
                  >
                    {caracteristica.icon}
                  </span>
                  <p className="mb-0 text-center">{caracteristica.label}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandIcons;
