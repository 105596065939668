/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const TecnicalDetails = ({ enterprise }) => {
  const chunks = enterprise?.ficha_tecnica?.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 5);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    []
  );

  return (
    <div className="py-5 page-section" id="ficha">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-12 col-12">
            <div
              className="btn btn-primary f12 btn-lg d-inline-block mb-3"
              style={{ width: "140px" }}
            >
              Ficha técnica
            </div>
          </div>
          <div className="col-lg-10 col-md-12 col-12 col-sm-12">
            <div className="row">
              {chunks?.map((chunk, chunkIndex) => (
                <div className="col-md-6" key={chunkIndex}>
                  {chunk.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      <p className="f11 border-bottom mb-1 text-muted">
                        {item.label.toUpperCase()}
                      </p>
                      {
                        <p
                          className="mb-4"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.value.split(" ").map((text, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    display: "block",
                                  }}
                                >
                                  {text}&nbsp;
                                </span>
                              );
                            }
                            return (
                              <span style={{ display: "block" }}>
                                {text}&nbsp;
                              </span>
                            );
                          })}
                        </p>
                      }
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TecnicalDetails;
