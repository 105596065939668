import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createLeadRds } from "../../services/leads";
import { Input } from "../Utils/Input";

const Form = ({ enterpriseId }) => {
  const initialData = {
    nome: "",
    email: "",
    telefone: "",
    aceita_contato: true,
    id_empreendimento: "",
    uf: "SP",
    cidade: "",
  };
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!enterpriseId) return;
    setData((prev) => ({ ...prev, id_empreendimento: enterpriseId }));
  }, [enterpriseId]);

  const addPhoneMask = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  const handleChange = (field, value) => {
    if (field === "telefone") {
      value = addPhoneMask(value);
    }

    setData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await createLeadRds(data);
      setData(initialData);
      toast.success("Enviado com sucesso!", {
        position: "top-right",
        closeOnClick: true,
        autoClose: 2000,
      });
    } catch {
      toast.error("Falha ao enviar!", {
        position: "top-right",
        closeOnClick: true,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-4 py-sm-2 py-lg-3">
      <div className="container">
        <div className="row">
          <div
            className="form-pg border15 p-4"
            style={{
              backgroundColor: "#F7F7F7",
            }}
          >
            <h5 className="mb-1">
              Gostaria de mais informações sobre o empreendimento?
            </h5>
            <p>
              Preencha o formulário que, em breve, nosso time entrará em contato
              com você.
            </p>
            <form className="" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-12">
                  {" "}
                  <Input
                    type="text"
                    className="form-control"
                    id="form36"
                    placeholder="Nome"
                    value={data.nome}
                    onChange={(newValue) => handleChange("nome", newValue)}
                    required
                  />{" "}
                </div>
              </div>
              <div className="form-group">
                {" "}
                <Input
                  type="email"
                  className="form-control"
                  id="form39"
                  placeholder="Email"
                  value={data.email}
                  onChange={(newValue) => handleChange("email", newValue)}
                  required
                />{" "}
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 col-lg-8 col-sm-8 col-8">
                  {" "}
                  <Input
                    type="tel"
                    className="form-control"
                    id="form40"
                    placeholder="Telefone"
                    value={data.telefone}
                    onChange={(newValue) => handleChange("telefone", newValue)}
                    required
                  />{" "}
                </div>
                <div className="form-group col-md-6 col-lg-4 col-sm-4 col-4">
                  <select
                    className="form-control"
                    id="Cidades"
                    value={data.uf}
                    onChange={(e) => handleChange("uf", e.target.value)}
                  >
                    <option value="SP">SP</option>
                    <option value="SC">SC</option>
                    <option value="PR">PR</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                {" "}
                <Input
                  type="text"
                  className="form-control"
                  id="form42"
                  placeholder="Cidade"
                  value={data.cidade}
                  onChange={(newValue) => handleChange("cidade", newValue)}
                  required
                />{" "}
              </div>
              <button
                type="submit"
                className="btn btn-outline-secondary float-right fbold"
                disabled={loading}
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
