import React from "react";
import { Img } from "../../Utils/Img";
const AboutActionTimeWeb = () => {
  return (
    <div className="py-5 bg-secondary d-none d-xl-block">
      <div className="container px-4">
        <div className="row">
          <div className="col-md-6 p-3">
            <h2 className="col-8 p-0">Está na hora de partir para a ação!</h2>
            <p className="">
              A Vizi tem loteamentos para todos os tipos de público, para que
              você tire seus planos do papel e vá com tudo ruma à realização de
              seus sonhos.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <Img
              className="d-block float-left mr-2 mb-4  img-fluid"
              src="assets/img/ab-lot.svg"
              width="50px"
            />
            <h5 className="mb-1 fbold">Lotes</h5>
            <p className="mb-0 fbold">Realizamos juntos</p>
            <p>
              Acreditamos que essa conquista mudará sua vida e nos empenhamos
              para tornar ela possível, com as melhores opções.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 p-3">
            <Img
              className="img-fluid d-block float-left mr-3 mb-4"
              src="assets/img/ab-pes.svg"
              width="52px"
            />
            <h5 className="mb-1 fbold">Pessoas</h5>
            <p className="mb-0 fbold">Crescemos em boa companhia</p>
            <p>
              A Vizi Lotes tem uma missão clara: viabilizar a construção de
              sonhos e, para isso, a nossa equipe não mede esforços.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <Img
              className="img-fluid d-block float-left mr-3 mb-4"
              src="assets/img/ab-tec.svg"
              width="52px"
            />
            <h5 className="mb-1 fbold">Tecnologia</h5>
            <p className="mb-0 fbold">Espaço para seus sonhos</p>
            <p>
              Por isso investimos em tecnologia, processos e formas de fazer
              acontecer sua mudança, sempre ao seu lado.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutActionTimeWeb;
