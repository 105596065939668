import React from "react";
import { Img } from "../../Utils/Img";

const AboutTeamWeb = () => {
  return (
    <div className="py-5 d-none d-xl-block">
      <div className="container">
        <div className="row pb-3">
          <div className="col-md-4">
            <h6 className="f13">Nosso time</h6>
            <h2 className="mt-0 px-0 col-11 col-lg-10">
              Um time completo à sua disposição
            </h2>
            <p>
              A Vizi Lotes faz parte de um ecossistema integrado que abrange
              desde o planejamento de projetos de urbanismo até a oferta de
              soluções de crédito imobiliário. Através de tecnologia de ponta e
              uma visão estratégica, a Vizi está na vanguarda da transformação e
              do desenvolvimento sustentável no mercado imobiliário, facilitando
              o acesso à moradia para dezenas de milhares de pessoas.
            </p>
          </div>
          <div className="col-md-4 d-flex justify-content-center flex-column align-items-center">
            <Img
              className="d-block img-fluid border15"
              src="assets/img/br1.jpg"
            />
            <p className="legend1 d-inline-block mb-0 pt-1 pb-1">Atendimento</p>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
            <Img
              className="d-block img-fluid border15"
              src="assets/img/br2.jpg"
            />
            <p className="legend1 d-inline-block mb-0 pt-1 pb-1">Comercial</p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
            <Img
              className="d-block img-fluid border15"
              src="assets/img/br3.jpg"
            />
            <p className="legend1 d-inline-block mb-0 pt-1 pb-1">
              Gestão de contas
            </p>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
            <Img
              className="d-block img-fluid border15"
              src="assets/img/br4.jpg"
            />
            <p className="legend1 d-inline-block mb-0 pt-1 pb-1">Jurídico</p>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
            <Img
              className="d-block img-fluid border15"
              src="assets/img/br5.jpg"
            />
            <p className="legend1 d-inline-block mb-0 pt-1 pb-1">Análises</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTeamWeb;
