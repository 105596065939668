import React from "react";

const BusinessBanner = () => {
  return (
    <div
      className="py-5  text-white"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, .3)), url('assets/img/couple1.jpg')`,
        backgroundPosition: "center center,center",
        backgroundSize: "cover, cover",
        backgroundRepeat: "repeat, no-repeat",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-10 pl-3">
            <h6 className="fbold f13">Seu novo lote está aqui</h6>
            <h2>
              Lotes selecionados
              <br /> próximos de você
            </h2>
            <p className="mb-3">
              Na Vizi Lotes, simplificamos o caminho para que você alcance seu
              sonho de <br /> possuir um terreno próprio. Aqui você encontrará
              lotes negociados com os principais <br /> loteadores do Brasil,
              garantindo os melhores produtos para você.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessBanner;
