import GLightbox from "glightbox";
import React, { useEffect, useState } from "react";
import { Img } from "../../Utils/Img";

const LandGalleryTablet = ({ images = [] }, implantacao) => {
  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".glightbox",
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
      openEffect: "zoom",
      closeEffect: "zoom",
      slideEffect: "slide",
      fullscreen: true,
      zoomable: true,
    });

    return () => {
      lightbox.destroy();
    };
  }, [images]);

  const [focusedTab, setFocusedTab] = useState("fotos");

  return (
    <div className="py-3 page-section" id="galeria">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-12">
            <button
              className={`btn f12 btn-lg d-inline-block ${
                focusedTab === "fotos" ? "btn-primary" : "btn-outline-info"
              } mb-1 mr-2`}
              style={{ width: "140px" }}
              onClick={() => setFocusedTab("fotos")}
            >
              Fotos
            </button>
            <button
              className={`btn f12 btn-lg d-inline-block ${
                focusedTab === "implantacao"
                  ? "btn-primary"
                  : "btn-outline-info"
              } mb-1`}
              style={{ width: "140px" }}
              onClick={() => setFocusedTab("implantacao")}
            >
              Implantação
            </button>
          </div>
          <div className="col-lg-10 col-md-12 col-12 col-sm-12">
            <div className="fotos-h">
              {focusedTab === "fotos" ? (
                images.map((image, index) => (
                  <a key={index} href={image.url} className="glightbox">
                    <Img
                      src={image.url}
                      className="m-1"
                      style={{ height: "220px", width: "auto" }}
                      alt={`Image ${index + 1}`}
                    />
                  </a>
                ))
              ) : (
                <>
                  <Img
                    className="m-1"
                    style={{ height: "400px", width: "auto" }}
                    src={implantacao.url}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandGalleryTablet;
