import React from "react";
import { useMediaQuery } from "react-responsive";

import WebComponent from "./TestimonialWebItem";
import TabletComponent from "./TestimonialTabletItem";

const TestimonialItem = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <div>
      {isDesktop && <WebComponent />}
      {(isTablet || isMobile) && <TabletComponent />}
    </div>
  );
};

export default TestimonialItem;
