import React from "react";
import Arround from "./Localization/Arround";
import Maps from "./Localization/Maps";

const Localization = ({ enterprise }) => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          <Arround enterprise={enterprise} />
          <Maps coordinates={enterprise?.coordinates} />
        </div>
      </div>
    </div>
  );
};

export default Localization;
