import React, { useEffect, useState } from "react";
import { Input } from "../Utils/Input";
import { scrollToHashOnPageLoad } from "../../common/common";
import { createLeadRds } from "../../services/leads";

const FaqContact = () => {
  const initialData = {
    "nome": "",
    "email": "",
    "telefone": "",
    "aceita_contato": true,
    "id_empreendimento": "",
    "uf": "SP",
    "cidade": ""
  };
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  const addPhoneMask = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g,'');
    value = value.replace(/(\d{2})(\d)/,"($1) $2");
    value = value.replace(/(\d)(\d{4})$/,"$1-$2");
    return value;
  }

  const handleChange = (field, value) => {
    if (field === "telefone") {
      value = addPhoneMask(value);
    }

    setData(prev => ({ ...prev, [field]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    await createLeadRds(data);

    setLoading(false);
  }

  return (
    <section id="contato">
      <div
        className="py-5"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url('assets/img/faq2.jpg')`,
          backgroundPosition: "top left, center top",
          backgroundSize: "100%, cover",
          backgroundRepeat: "repeat, no-repeat",
          minHeight: "400px",
        }}
      >
        <div className="container px-md-5 px-4">
          <div className="row mt-xl-3 mt-md-2 mt-5">
            <div className="col-lg-4 text-white pr-3 d-flex flex-column justify-content-end col-10 mx-auto col-sm-5 col-md-5">
              <h6 className="f13">FALE CONOSCO</h6>
              <h2 className="mt-0 px-0">Estamos prontos para atender você</h2>
              <p>
                Preencha o formulário e solicite um atendimento especializado do
                nosso time. Estamos te aguardando.
                <br />
              </p>
            </div>
            <div className="d-flex justify-content-end col-sm-7 col-md-7 col-lg-8">
              <div
                className="form-pg2 border15 p-4"
                style={{ backgroundColor: "#F7F7F7" }}
              >
                <h5 className="mb-1 fbold">
                  Preencha o formulário com seus dados
                </h5>
                <p className="f12">
                  Fique tranquilo, suas informações estão seguras conosco, com
                  base na Lei de Proteção de Dados
                </p>
                <form className="" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      {" "}
                      <Input
                        type="text"
                        className="form-control"
                        id="form36"
                        placeholder="Nome"
                        value={data.nome}
                        onChange={(newValue) => handleChange("nome", newValue)}
                        required
                      />{" "}
                    </div>
                  </div>
                  <div className="form-group">
                    {" "}
                    <Input
                      type="email"
                      className="form-control"
                      id="form39"
                      placeholder="Email"
                      value={data.email}
                      onChange={(newValue) => handleChange("email", newValue)}
                      required
                    />{" "}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-lg-8 col-sm-8 col-8">
                      {" "}
                      <Input
                        type="tel"
                        className="form-control"
                        id="form40"
                        placeholder="Telefone"
                        value={data.telefone}
                        onChange={(newValue) => handleChange("telefone", newValue)}
                        required
                      />{" "}
                    </div>
                    <div className="form-group col-md-6 col-lg-4 col-sm-4 col-4">
                      <select className="form-control" id="Cidades" value={data.uf} onChange={(e) => handleChange("uf", e.target.value)}>
                        <option value="SP">SP</option>
                        <option value="SC">SC</option>
                        <option value="PR">PR</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    {" "}
                    <Input
                      type="text"
                      className="form-control"
                      id="form42"
                      placeholder="Cidade"
                      value={data.cidade}
                      onChange={(newValue) => handleChange("cidade", newValue)}
                      required
                    />{" "}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-outline-secondary float-right fbold"
                    disabled={loading}
                  >
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqContact;
