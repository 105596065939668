/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Img } from "../Utils/Img";

const LandMenu = ({ enterprise }) => {
  return (
    <div className="py-1 bg-dark-blue sticky-top pb-2">
      <div className="container ">
        <div className="row">
          <div
            className="col-lg-12 mt-1 d-none d-xl-block px-0 nav-emp navigation"
            id="nav-emp"
          >
            <a className="btn-link mr-1 f13 disabled text-white" href="#">
              {enterprise.nome}
              <Img src="assets/img/arrow-r.svg" className="ml-1" />
            </a>
            <a className="btn btn-link mr-1 f13 active" href="#toptitle">
              Informações
            </a>
            <a className="btn btn-link mr-1 f13" href="#galeria">
              Galeria
            </a>
            <a className="btn btn-link mr-1 f13" href="#localizacao">
              Localização
            </a>
            <a className="btn btn-link mr-1 f13" href="#ficha">
              Ficha técnica
            </a>
            <a className="btn btn-link mr-1 f13" href="#status">
              Status da obra
            </a>
            <a
              className="btn btn-success mr-1 f13 fbold green1 float-right"
              href={`https://api.whatsapp.com/send?phone=554137791190&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20empreendimento%20${enterprise.nome}`}
              target="_blank"
            >
              <span className="text-light">WhatsApp</span>
              <i className="fa fa-whatsapp fa-1x text-white ml-1 fa-lg"></i>
            </a>
          </div>

          <div
            className="d-xl-none"
            style={{ width: "150%", overflowX: "scroll", paddingLeft: "50px" }}
          >
            <div
              id="nav-emp-mini"
              className="col-lg-12 mt-1 d-xl-none px-0 d-flex justify-content-center align-items-center nav-emp navigation"
            >
              <a className="btn btn-link f11" href="#toptitle">
                Informações
              </a>
              <a className="btn btn-link f11" href="#galeria">
                Galeria
              </a>
              <a className="btn btn-link f11" href="#localizacao">
                Localização
              </a>
              <a className="btn btn-link f11" href="#ficha">
                Ficha técnica
              </a>
              <a className="btn btn-link f11" href="#status">
                Status da obra
              </a>
            </div>

            <div className="nav d-lg-none d-sm-block d-md-block">
              <a
                className="wts2"
                href={`https://api.whatsapp.com/send?phone=554137791190&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20empreendimento%20${enterprise.nome}`}
                target="_blank"
              >
                <i className="fa fa-whatsapp text-white fa-2x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandMenu;
