import React from "react";
import { Img } from "../../Utils/Img";
import { useMediaQuery } from "react-responsive";
import { testimonials } from "./TestimonialData";

const TestimonialItem = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <div className="owl-stage-outer">
      <div className="owl-stage testimonials-carousel">
        {testimonials.map((testimonial) => (
          <div className="owl-item testimonials-carousel-item active">
            <div className="owl-item">
              <div className="card">
                <div className="row">
                  <div className="col-lg-4 col-4 pr-0 mr-0">
                    <Img
                      className="img-fluid d-block border15"
                      src={
                        testimonial.logo_enterprise ||
                        "assets/img/vizi-logo-branca.png"
                      }
                      style={{ width: "160px" }}
                    />
                  </div>
                  <div
                    className="col-lg-7 col-7 text-left ml-2 pl-0"
                    style={{ alignContent: "end" }}
                  >
                    <h6 className="fbold d-inline mb-1">{testimonial.name}</h6>
                    <p className="fbold">{testimonial.enterprise} </p>
                    <p className="fnormal">{testimonial.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialItem;
