import React from "react";
import { Img } from "../../components/Utils/Img";
const Footer = () => {
  return (
    <div>
      <div
        className={`${
          window.location.pathname === "/empreendimentos" ||
          window.location.pathname === "/lote"
            ? "bg-dark-blue"
            : "bg-secondary"
        }`}
      >
        <div className="container  p-4">
          <div className="row">
            <div className="col-lg-2 p-0 d-none d-lg-block">
              <h6 className="text-uppercase fbold mb-2 small  text-white">
                HOME
              </h6>
              <ul className="list-unstyled f12">
                <a href="/#sobre">
                  <label className="mb-1 mt-0">Sobre a Vizi</label>
                </a>
                <br />
                <a className="mb-1" href="/#vantagens">
                  <label className="mb-1 mt-0">Vantagens</label>
                </a>
                <br />
                <a className="mb-2" href="/#empreendimentos">
                  <label className="mb-1 mt-0">Empreendimentos</label>
                </a>{" "}
                <br />
                <a
                  className="mb-1"
                  href="https://api.whatsapp.com/send?phone=554137791190"
                >
                  <label className="mb-1 mt-0">Entre em contato</label>
                </a>{" "}
                <br />
                <a className="mb-2" href="/#horademudar">
                  <label className="mb-1 mt-0"> A hora de mudar é agora</label>
                </a>{" "}
                <br />
                <a className="mb-2" href="/#depoimentos">
                  <label className="mb-1 mt-0">Depoimentos</label>
                </a>
              </ul>
            </div>
            <div className="col-lg-2 p-0  d-none d-lg-block">
              <h6 className="text-uppercase fbold mb-2 small text-white">
                Sobre
              </h6>
              <ul className="list-unstyled f12">
                <a className="mb-2" href="/sobre#sobre">
                  <label className="mb-1 mt-0">Vizi</label>
                </a>
                <br />
                <a className="mb-2" href="/sobre#vantagens">
                  <label className="mb-1 mt-0">Vantagens Vizi Lotes</label>
                </a>{" "}
                <br />
                <a className="mb-2" href="/sobre#programavantagens">
                  <label className="mb-1 mt-0">Programa de vantagens</label>
                </a>
              </ul>
            </div>
            <div className="col-lg-2 p-0  d-none d-lg-block">
              <h6 className="text-uppercase  fbold small mb-2 text-white">
                <label className="mb-1 mt-0">empreendimentos</label>
              </h6>
              <ul className="list-unstyled f12">
                <a className="mb-2" href="/empreendimentos">
                  <label className="mb-1 mt-0">
                    Principais empreendimentos
                  </label>
                </a>{" "}
                <br />
              </ul>
            </div>
            <div className="col-lg-3 p-0 pl-sm-0 pl-md-0 pl-lg-2 border-right border-light  d-none d-lg-block">
              <h6 className="mb-2 text-uppercase  fbold small text-white">
                <label className="mb-1 mt-0">perguntas frequentes</label>
              </h6>
              <ul className="list-unstyled f12">
                <a className="mb-2" href="/faq">
                  Principais perguntas
                </a>{" "}
                <br />
              </ul>
            </div>
            <div id="footprint" className="p-2 col-lg-3  col-12">
              <div className="row">
                <div className="col-lg-12 col-md-4 col-sm-5 col-12">
                  <Img
                    src="assets/img/logo-vizi.svg"
                    height="68px"
                    className="mb-2"
                  />
                </div>
                <div className="col-lg-12 col-md-8 col-sm-7 col-12 text-white">
                  <p className="mr-2 d-inline-block f11">
                    <a className="mb-2" href="#">
                      (41) 3779-1190
                    </a>
                  </p>
                  <p className="mr-0 d-inline-block f11">
                    <a className="mb-2" href="#">
                      contato@vizi.com.br
                    </a>
                  </p>
                  <p className="mr-0  f11">
                    R.General Mauro Tourinho, 1805 - Sala 1601 <br />
                    Seminário, Curitiba/PR - CEP 80740-000
                  </p>
                  <a
                    className="mb-2"
                    href="https://api.whatsapp.com/send?phone=554137791190"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      className="d-inline-block mr-2"
                      src="assets/img/icbaselinewhatsapp.svg"
                      height="26px"
                    />
                  </a>
                  <a
                    className="mb-2"
                    href="https://www.facebook.com/vizilotes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      className="d-inline-block mr-2"
                      src="assets/img/lafacebook.svg"
                      height="28px"
                    />
                  </a>
                  <a
                    className="mb-2"
                    href="https://www.instagram.com/vizilotes/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      className="d-inline-block mr-2"
                      src="assets/img/iconoirinstagram.svg"
                      height="28px"
                    />
                  </a>
                  <a
                    className="mb-2"
                    href="https://www.youtube.com/@bairru5789"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      className="d-inline-block mr-2"
                      src="assets/img/ic-yt.svg"
                      height="28px"
                    />
                  </a>
                  <a
                    className="mb-2"
                    href="https://www.linkedin.com/company/bairru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      className="d-inline-block mr-2"
                      src="assets/img/linkedin.svg"
                      height="23px"
                      style={{ marginBottom: "2px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`py-1 ${
          window.location.pathname === "/empreendimentos" ||
          window.location.pathname === "/lote"
            ? "bg-primary"
            : "bg-orange-strong"
        } navbar-dark`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="text-center f11 mb-1 text-white">
                Copyright ©️ 2024 Vizi Lotes - Todos os direitos reservados.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
