import React from "react";
import { useMediaQuery } from "react-responsive";
import AboutActionTimeWeb from "./AboutActionTime/AboutActionTimeWeb";
import AboutActionTimeTablet from "./AboutActionTime/AboutActionTimeTablet";

const AboutActionTime = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <div>
      {isDesktop && <AboutActionTimeWeb />}
      {(isTablet || isMobile) && <AboutActionTimeTablet />}
    </div>
  );
};

export default AboutActionTime;
