import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetch = async () => {
  try {
    const response = await api.get(`/enterprises/V2/filtros`);

    return response.data;
  } catch (error) {
    console.error("Error fetching all:", error);
    throw error;
  }
};
