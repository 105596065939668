import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../common/common";

const Enterprises = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="text-center pt-4 pb-0 mt-5" id="empreendimentos">
      <div className="container">
        <div className="row my-4 justify-content-center">
          <div className="col-md-6">
            <div className="lineup mt-0 mb-2 mx-auto bg-primary"></div>
            <h2>Empreendimentos</h2>
            <p>
              Confira os empreendimentos disponíveis no site da Vizi Lotes e
              encontre, aqui, seu próximo <br /> endereço ou investimento
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enterprises;
