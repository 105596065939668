/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { FilterContext } from "../../../context/filterContext";

const EnterpriseTabletItem = () => {
  const { filter, setFilter } = useContext(FilterContext);

  function clearPagination() {
    if (filter.pagination) {
      filter.pagination.page = 1;
      filter.pagination.limit = 4;
    }
  }

  const handleCityChange = (city) => {
    clearPagination();
    // setFilter({ ...filter, city });
  };

  const handleStateChange = (state) => {
    clearPagination();
    // setFilter({ ...filter, state });
  };

  const handleStatusChange = (status) => {
    clearPagination();
    // setFilter({ ...filter, status });
  };

  const cities = [
    { id: 1, name: "Cidade" },
    { id: 2, name: "São José dos Pinhais" },
    { id: 3, name: "Curitiba" },
  ];

  const states = ["Estado", "SP", "PR", "SC", "RS"];

  const statuses = [
    "Status",
    "Pré-lançamento",
    "Lançamento",
    "Em obra",
    "Pronto para morar",
    "Pronto para construir",
  ];

  return (
    <div className="col-lg-12 mt-1 d-none d-lg-block d-md-block  d-xl-none text-center">
      <select
        className="form-control d-inline-block border-d f13 rox px-2 mr-2 mb-0"
        id="Cidades"
        style={{ width: "186px", marginTop: "-2px" }}
        onChange={(e) => handleCityChange(e.target.value)}
        value={filter.city}
      >
        {cities.map((city) => (
          <option key={city.id} value={city.name}>
            {city.name}
          </option>
        ))}
      </select>

      <select
        className="form-control d-inline-block border-d f13 rox px-2 mr-2 mb-0"
        id="Estados"
        style={{ width: "186px", marginTop: "-2px" }}
        onChange={(e) => handleStateChange(e.target.value)}
        value={filter.state}
      >
        {states.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>

      <select
        className="form-control d-inline-block border-d f13 rox px-2 mr-2 mb-0"
        id="Status"
        style={{ width: "186px", marginTop: "-2px" }}
        onChange={(e) => handleStatusChange(e.target.value)}
        value={filter.status}
      >
        {statuses.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>

      {window.location.pathname === "/empreendimentos" ? (
        <a
          className="btn btn-success mr-1 mb-1 f13 fbold green1"
          href="https://api.whatsapp.com/send?phone=554137791190"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-light">Whatsapp</span>
          <i className="fa fa-whatsapp fa-2x text-white ml-1"></i>
        </a>
      ) : (
        <a className="btn btn-outline-light f13 fbold rox mr-2" href="#">
          <span className="text-light">Ver todos</span>
        </a>
      )}
    </div>
  );
};

export default EnterpriseTabletItem;
