import React from "react";
import Enterprise from "./Enterprise";

const EnterpriseWebItem = ({ enterprises = [] }) => {
  return (
    <div className="py-4 text-center">
      <div className="container">
        <div className="row">
          {enterprises.map(
            (item, index) => item && <Enterprise item={item} key={index} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EnterpriseWebItem;
