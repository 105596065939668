import React from "react";
import { Img } from "../../Utils/Img";
const AboutActionTimeTablet = () => {
  return (
    <div className="py-5 bg-secondary d-xl-none">
      <div className="container px-4">
        <div className="row">
          <div className="col-md-8 p-2">
            <h2 className="p-0 col-7">Está na hora de partir para a ação!</h2>
          </div>
          <div className="col-md-6 d-flex align-items-center p-2">
            <p>
              A Vizi tem loteamentos para todos os tipos de público, para que
              você tire seus planos do papel e vá com tudo ruma à realização de
              seus sonhos.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 p-2">
            <div className="line-left2 d-inline d-none d-sm-block d-md-none float-left pr-1">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-4 pb-3 mb-md-5"
              src="assets/img/ab-lot.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Lotes</h6>
            <p className="mb-0 fbold">Realizamos juntos</p>
            <p className="f12">
              Acreditamos que essa conquista mudará sua vida e nos empenhamos
              para tornar ela possível, com as melhores opções.
            </p>
          </div>
          <div className="col-md-4 p-2">
            <div className="line-left2 d-inline d-none d-sm-block d-md-none float-left pr-1">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-4 pb-3 mb-md-5"
              src="assets/img/ab-pes.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Pessoas</h6>
            <p className="mb-0 fbold">Crescemos em boa companhia</p>
            <p className="f12">
              A Vizi Lotes tem uma missão clara: viabilizar a construção de
              sonhos e, para isso, a nossa equipe não mede esforços.
            </p>
          </div>
          <div className="col-md-4 p-2">
            <div className="line-left2 d-inline d-none d-sm-block d-md-none float-left pr-1">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-4 pb-3 mb-md-5"
              src="assets/img/ab-tec.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Tecnologia</h6>
            <p className="mb-0 fbold">Espaço para seus sonhos</p>
            <p className="f12">
              Por isso investimos em tecnologia, processos e formas de fazer
              acontecer sua mudança, sempre ao seu lado.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutActionTimeTablet;
