import React from "react";
import { useMediaQuery } from "react-responsive";
import AboutInfoWeb from "./AboutInfo/AboutInfoWeb";
import AboutInfoTablet from "./AboutInfo/AboutInfoTablet";
import AboutInfoMobile from "./AboutInfo/AboutInfoMobile";

const AboutInfo = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <div>
      {isDesktop && <AboutInfoWeb />}
      {isTablet && <AboutInfoTablet />}
      {isMobile && <AboutInfoMobile />}
    </div>
  );
};

export default AboutInfo;
