import React from "react";
import { Img } from "../Utils/Img";
import LandStatusItem from "./Status/LandStatusItem";

const statusIcons = {
  terra_planagem: "assets/img/l-trator.svg",
  drenagem: "assets/img/l-pipe.svg",
  saneamento: "assets/img/l-pipe2.svg",
  pavimentacao: "assets/img/l-compressor.svg",
  rede_eletrica: "assets/img/l-energy.svg",
};

const statusLabels = {
  terra_planagem: "Terraplanagem",
  drenagem: "Drenagem",
  saneamento: "Saneamento",
  pavimentacao: "Pavimentação",
  rede_eletrica: "Rede elétrica",
};

const Status = ({ status_da_obra }) => {
  return (
    <div className="bg-primary status-o py-4 page-section" id="status">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-6 mx-auto">
            <div className="lineup mt-0 mb-2 mx-auto bg-light"></div>
            <h3>Status da obra</h3>
            <p className="f12">TOTAL CONCLUÍDO {status_da_obra.total}</p>
          </div>
        </div>
        <div className="cards-h ml-1">
          <div className="container-fluid-cards">
            <div className="px-0 col-lg-12 d-flex flex-row">
              {status_da_obra &&
                Object.keys(status_da_obra)
                  .filter((key) => key !== "total") // Filtra a chave 'total'
                  .map((key) => (
                    <div
                      className="d-inline-block border15 m-1 icons3"
                      key={key}
                    >
                      <div className="d-inline-block float-right mt-0">
                        <LandStatusItem
                          percentage={status_da_obra[key]}
                          className="d-inline-block float-right mt-0"
                        />
                      </div>
                      <Img
                        src={statusIcons[key]}
                        height="36px"
                        className="mt-2"
                      />
                      <p className="mb-0 mt-3 text-body">{statusLabels[key]}</p>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
