import React from "react";
import { Img } from "../Utils/Img";

const Numbers = () => {
  const styleN = { fontSize: "70px" };
  const styleS = { fontSize: "70px", letterSpacing: "-6px" };

  return (
    <div>
      <div className="py-5 bg-primary d-none d-sm-block">
        <div className="container">
          <div className="row flex-row align-items-center d-flex justify-content-center">
            <div className="col-lg-3 col-7 col-sm-6">
              <div className="row justify-content-center d-flex">
                <div className="d-flex align-items-center col-lg-8 col-9">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleN} className="mb-0 ml-2">
                    41
                  </h1>
                </div>
                <div className="col-lg-9 col-10">
                  <p className="pill1 text-uppercase text-white">
                    Projetos viabilizados
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-7 col-md-6">
              <div className="row justify-content-center d-flex">
                <div className="d-flex align-items-center col-lg-8 col-9">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleN} className="mb-0 ml-2">
                    29
                  </h1>
                </div>
                <div className="col-lg-8 col-9">
                  <p className="pill1 text-uppercase text-white">
                    cidades presentes
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-7 col-md-6">
              <div className="row justify-content-center d-flex">
                <div className="d-flex align-items-center   col-lg-11">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleS} className="mb-0 ml-2">
                    20mil
                  </h1>
                </div>
                <div className="col-lg-11">
                  <p className="pill1 text-uppercase text-white">
                    lotes viabilizados
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-7 col-md-6">
              <div className="row justify-content-center d-flex">
                <div className="d-flex align-items-center   col-lg-11">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleS} className="mb-0 ml-2">
                    904M
                  </h1>
                </div>
                <div className="col-lg-11">
                  <p className="pill1 text-uppercase text-white">VGV Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5 bg-primary  d-block d-sm-none">
        <div className="container">
          <div className="row flex-row align-items-center d-flex justify-content-start">
            <div className="col-lg-3 col-7 col-sm-6">
              <div className="row d-flex justify-content-start">
                <div className="d-flex align-items-center  col-lg-8 col-9">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleN} className="mb-0 ml-2">
                    41
                  </h1>
                </div>
                <div className="col-lg-9 col-10">
                  <p className="pill1 text-uppercase text-white">
                    Projetos viabilizados
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-7 col-md-6">
              <div className="row justify-content-center d-flex">
                <div className="d-flex align-items-center   col-lg-11">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleS} className="mb-0 ml-2">
                    20mil
                  </h1>
                </div>
                <div className="col-lg-11">
                  <p className="pill1 text-uppercase text-white">
                    lotes viabilizados
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-7 col-md-6">
              <div className="row d-flex justify-content-start">
                <div className="d-flex align-items-center  col-lg-8 col-9">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleN} className="mb-0 ml-2">
                    29
                  </h1>
                </div>
                <div className="col-lg-8 col-9">
                  <p className="pill1 text-uppercase text-white">
                    cidades presentes
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-7 col-md-6">
              <div className="row justify-content-center d-flex">
                <div className="d-flex align-items-center   col-lg-11">
                  <p className="border p-1">+ de</p>
                  <h1 style={styleS} className="mb-0 ml-2">
                    904M
                  </h1>
                </div>
                <div className="col-lg-11">
                  <p className="pill1 text-uppercase text-white">VGV Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;
