import React from "react";
import { ToastContainer } from "react-toastify";
import { checkCurrentPath } from "../../common/common";
import { Img } from "../../components/Utils/Img";

const Header = () => {
  return (
    <div className="app-header">
      <ToastContainer />

      <nav
        style={{ zIndex: "1221" }}
        className={`navbar navbar-expand-md ${
          window.location.pathname === "/empreendimentos" ||
          window.location.pathname === "/lote"
            ? "bg-primary"
            : "bg-secondary"
        } navbar-dark`}
      >
        <div className="container">
          <a className="navbar-brand text-primary" href="/">
            <Img className="img-fluid d-block" src="assets/img/logo-vizi.svg" />
          </a>
          <button
            className="navbar-toggler navbar-toggler-right border-0 collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbar4"
            aria-expanded="false"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse" id="navbar4">
            <ul
              className={`navbar-nav ml-auto ${
                window.location.pathname === "/empreendimentos" ||
                window.location.pathname === "/lote"
                  ? "bg-primary"
                  : "bg-secondary"
              }`}
            >
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    checkCurrentPath("/") ? "header-active" : ""
                  }`}
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    checkCurrentPath("/empreendimentos") ? "header-active" : ""
                  }`}
                  href="empreendimentos"
                >
                  Empreendimentos
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    checkCurrentPath("/sobre") ? "header-active" : ""
                  }`}
                  href="/sobre"
                >
                  Sobre
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    checkCurrentPath("/faq") ? "header-active" : ""
                  }`}
                  href="/faq#contato"
                >
                  Contato
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
