import { useState } from "react";
import { Img } from "../../Utils/Img";

const Enterprise = ({ item }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className="col-12 mx-auto col-lg-6 mt-4"
      onMouseOver={() => {
        setIsFocused(true);
      }}
      onMouseLeave={() => setIsFocused(false)}
    >
      <div
        className="card border15"
        style={{
          backgroundImage: `url("${
            item.banner?.url || "assets/img/pic01.jpg"
          }")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "400px",
          width: "100%",
          minWidth: "200px",
          maxWidth: "800px",
          overflow: "hidden",
        }}
      >
        <div className="card-body p-0 h-full">
          <div
            className="d-flex mb-2 p-0 h-full w-full text-left"
            style={{
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              className="p-3 pr-0 col-lg-5 h-full"
              style={{
                backgroundColor: isFocused
                  ? "rgba(0, 0, 0, 0.7)"
                  : "rgba(255, 255, 255, 0)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "background-color .5s",
              }}
            >
              <Img
                className="img-fluid d-block float-right"
                src={item.logo?.url || "assets/img/logo-empreend.png"}
                alt={item.nome_empreendimento || "Image"}
                style={{
                  maxWidth: "100px",
                  maxHeight: "70px",
                  width: "auto",
                  height: "auto",
                  position: "absolute",
                  right: -40,
                  top: 50,
                  opacity: isFocused ? 1 : 0,
                  transition: "opacity .5s",
                }}
              />
              <div>
                <h6
                  className="fbold text-uppercase d-inline-block text-white"
                  style={{
                    width: "130px",
                  }}
                >
                  {item.nome_empreendimento || "-"}
                </h6>
                <p
                  className="mb-0 f10 text-white"
                  style={{
                    opacity: isFocused ? 1 : 0,
                    transition: "opacity .5s",
                  }}
                >
                  {item.endereco?.cidade || "-"}
                </p>
                <p
                  className="f10 text-white"
                  style={{
                    opacity: isFocused ? 1 : 0,
                    transition: "opacity .5s",
                  }}
                >
                  {`Lotes a partir de ${item.area_min || "-"}` || "-"}
                </p>
              </div>
              <div className="row text-white">
                <div className="col-md-12 d-flex flex-column justify-content-end align-items-start">
                  <div
                    className="card-status2 mb-1"
                    style={{
                      opacity: isFocused ? 1 : 0,
                      transition: "opacity .5s",
                    }}
                  >
                    {item.endereco.uf || "-"}
                  </div>
                  <div
                    className="card-status2 mb-1"
                    style={{
                      opacity: isFocused ? 1 : 0,
                      transition: "opacity .5s",
                    }}
                  >
                    {item.tipo || "-"}
                  </div>
                  <div className="card-status2">
                    {item.status_empreendimento || "-"}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex mb-3 mr-4"
              style={{
                height: "fit-content",
                alignSelf: "flex-end",
              }}
            >
              <a
                className="btn btn-secondary fbold f13"
                href={"/lote?id=" + item.id_empreendimento}
                style={{
                  opacity: isFocused ? 1 : 0,
                  transition: "opacity .5s",
                }}
              >
                Conheça
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enterprise;
