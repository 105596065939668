import React from "react";
import AboutInfo from "../components/About/AboutInfo";
import AboutInfoBanner from "../components/About/AboutInfoBanner";
import AboutActionTime from "../components/About/AboutActionTime.js";
import AboutBenefits from "../components/About/AboutBenefits";
import AboutPartnerBanner from "../components/About/AboutPartnerBanner.js";
import AboutPartnerBenefits from "../components/About/AboutPartnerBenefits.js";
import AboutTeam from "../components/About/AboutTeam.js";

const About = () => {
  return (
    <div>
      <AboutInfo />
      <AboutInfoBanner />
      <AboutActionTime />
      <AboutBenefits />
      <AboutPartnerBanner />
      <AboutPartnerBenefits />
      <AboutTeam />
    </div>
  );
};

export default About;
