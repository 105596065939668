import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../common/common";
import { Img } from "../Utils/Img";

const BenefitsBanner = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="bg-primary" id="horademudar">
      <div className="container-fluid">
        <div className="row py-0">
          <div className="px-2 col-lg-6">
            <div className="row">
              <div className="col-md-2 d-none d-lg-block"> </div>
              <div className="col-lg-9 py-5 mb-3 ml-2">
                <h2 className="pt-3">A hora de mudar é agora</h2>
                <p className="mb-4">
                  Mudanças geralmente vêm para o bem.&nbsp;
                  <br />
                  Se acontecerem de maneira mais fácil, melhor.
                </p>
                <div className="row">
                  <div className="d-flex pr-0 pt-0 align-items-center col-3 justify-content-between mb-2 col-lg-2 col-md-3">
                    <div className="line-left d-inline d-lg-none"> </div>
                    <Img
                      className="py-0 my-0 mb-0 mr-2"
                      src="assets/img/ic-home.svg"
                      width="46px"
                    />
                  </div>
                  <div className="col-md-9 col-sm-9 col-lg-9 col-9">
                    <h5 className="fbold mb-1">Sua vida mais fácil</h5>
                    <p className="">
                      Na Vizi Lotes você encontrará o lugar perfeito para
                      realizar seus sonhos.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex pr-0 pt-0 align-items-center col-3 justify-content-between mb-2 col-lg-2 col-md-3">
                    <div className="line-left d-inline d-lg-none"> </div>
                    <Img
                      className="py-0 my-0 mr-2 mb-0"
                      src="assets/img/ic-like.svg"
                      width="46px"
                    />
                  </div>
                  <div className="col-lg-8 col-9">
                    <h5 className="fbold mb-1">Lotes selecionados</h5>
                    <p className="">
                      Buscamos as melhores opções em lotes para disponibilizar
                      para você.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex pr-0 pt-0 align-items-center col-3 justify-content-between mb-2 col-lg-2 col-md-3">
                    <div className="line-left d-inline d-lg-none"> </div>
                    <Img
                      className="py-0 my-0 mb-0 mr-2"
                      src="assets/img/ic-smile.svg"
                      width="46px"
                    />
                  </div>
                  <div className="col-lg-8 col-9">
                    <h5 className="fbold mb-1">Mais próximo de você</h5>
                    <p className="">
                      A Vizi está disponível para você o tempo todo. Oferecemos
                      serviços exclusivos, atendimento personalizado e
                      benefícios únicos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="px-2 col-lg-6 col-sm-12 benefits-banner-footer"
            style={{
              backgroundImage: `url("assets/img/family2.jpg")`,
              backgroundSize: "cover",
              backgroundPosition: "right bottom",
              backgroundRepeat: "none",
              minHeight: "350px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsBanner;
