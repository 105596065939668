import React from "react";
import styled from "styled-components";
const CircularProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 58px;
  height: 58px;
`;

const CircularProgressSvg = styled.svg`
  transform: rotate(-90deg);
  width: 58px;
  height: 58px;
`;

const CircularBackground = styled.circle`
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 6;
`;

const CircularProgress = styled.circle`
  fill: none;
  stroke: #df763e;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.offset};
  transition: stroke-dashoffset 0.5s;
`;

const CircularText = styled.text`
  fill: #6c757d;
  font-size: 15px;
  font-weight: bold;
  dominant-baseline: middle;
  text-anchor: middle;
  transform: rotate(90deg);
`;

const LandStatusItem = ({ percentage }) => {
  const radius = 25.5;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;
  const cx = 28.5;
  const cy = 28.5;
  return (
    <CircularProgressWrapper>
      <CircularProgressSvg width="68" height="68">
        <CircularBackground cx={cx} cy={cy} r={radius} />
        <CircularProgress
          cx={cx}
          cy={cy}
          r={radius}
          circumference={circumference}
          offset={offset}
        />
        <CircularText x="30" y="-27">
          {`${percentage}`}
        </CircularText>
      </CircularProgressSvg>
    </CircularProgressWrapper>
  );
};

export default LandStatusItem;
