import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[16px]",
};
const variants = {
  fill: {
    gray_200: "bg-gray-200 text-gray-800_4c",
  },
};
const sizes = {
  sm: "h-[56px] pl-6 pr-[35px] text-xl",
  xs: "h-[48px] pl-4",
};

const Input = React.forwardRef(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "fill",
      size = "xs",
      color = "gray_200",
      ...restProps
    },
    ref
  ) => {
    const handleChange = (e) => {
      if (onChange) onChange(e?.target?.value);
    };

    return (
      <>
        <input
          ref={ref}
          type={type}
          name={name}
          className={className}
          onChange={handleChange}
          placeholder={placeholder}
          {...restProps}
        />
      </>
    );
  }
);

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["sm", "xs"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["gray_200"]),
};

export { Input };
