import React from "react";
import { Img } from "../../Utils/Img";
import { useMediaQuery } from "react-responsive";
import { testimonials } from "./TestimonialData";
import { type } from "@testing-library/user-event/dist/type";

const TestimonialItem = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <div className="owl-stage-outer">
      <div className="owl-stage testimonials-carousel">
        {testimonials.map((testimonial, index) => (
          <div
            className="owl-item testimonials-carousel-item active"
            key={index}
          >
            <div className="owl-item active">
              <div className="card">
                <div className="row m-0 p-0">
                  <div className="col-lg-4 col-md-4">
                    <Img
                      className="img-fluid d-block border15"
                      src={
                        testimonial.logo_enterprise ||
                        "assets/img/vizi-logo-branca.png"
                      }
                      style={{ width: "160px" }}
                    />
                  </div>
                  <div className="col-lg-7 col-md-7 pt-2 text-left">
                    <h6 className="fbold">{testimonial.name}</h6>
                    <p className="fbold">{testimonial.enterprise} </p>
                    <p className="fnormal">{testimonial.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialItem;
