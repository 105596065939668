import React from "react";

const FaqBanner = () => {
  return (
    <div
      className="pt-5 pb-3 d-flex flex-column align-items-stretch"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url('assets/img/faq1.jpg')`,
        backgroundPosition: "top left, center",
        backgroundSize: "100%, cover",
        backgroundRepeat: "repeat, no-repeat",
      }}
    >
      <div className="container text-white">
        <div className="row mt-5">
          <div className="px-5 text-center mx-auto col-lg-7 col-md-7 mt-5">
            <h2 className="my-3">FAQ</h2>
            <p className="mt-2 fbold">
              {" "}
              <small>PERGUNTAS FREQUENTES</small>
            </p>
            <p className="mb-5">
              Tire aqui suas dúvidas sobre a Vizi ou, se preferir, preencha o
              <br />
              formulário e solicite um contato do nosso time de especialistas
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqBanner;
