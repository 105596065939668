import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchAll = async ({ params = "" }) => {
  try {
    const response = await api.get(`/enterprises/V2?${params}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching all:", error);
    throw error;
  }
};

export const fetchById = async (enterpriseId) => {
  try {
    const response = await api.get(`/enterprises/V2/${enterpriseId}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching all:", error);
    throw error;
  }
};
