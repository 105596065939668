import React from "react";
import { useMediaQuery } from "react-responsive";

import WebComponent from "./EnterpriseWebItem";
import TabletComponent from "./EnterpriseTabletItem";
import EnterpriseMobileItem from "./EnterpriseMobileItem";

const EnterpriseFiltersItem = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <div
      className={`${
        window.location.pathname === "/empreendimentos" ? "" : "py-1"
      }`}
    >
      <div
        className={`${
          window.location.pathname === "/empreendimentos" ? "" : "container"
        }`}
      >
        <div
          id="filter"
          className={`row pt-2 pb-1 ${
            window.location.pathname === "/empreendimentos"
              ? "bg-dark-blue"
              : "bg-primary border15"
          }`}
        >
          {isDesktop && <WebComponent />}
          {isTablet && <TabletComponent />}
          {isMobile && <EnterpriseMobileItem />}
        </div>
      </div>
    </div>
  );
};

export default EnterpriseFiltersItem;
