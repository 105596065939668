import React from "react";
import { useMediaQuery } from "react-responsive";
import AboutPartnerBenefitsWeb from "./AboutPartnerBenefits/AboutPartnerBenefitsWeb";
import AboutPartnerBenefitsTablet from "./AboutPartnerBenefits/AboutPartnerBenefitsTablet";

const AboutPartnerBenefits = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  return (
    <div>
      {isDesktop && <AboutPartnerBenefitsWeb />}
      {(isTablet || isMobile) && <AboutPartnerBenefitsTablet />}
    </div>
  );
};

export default AboutPartnerBenefits;
