import React, { useState } from "react";
import EnterpriseCarousel from "../../components/Home/EnterpriseCarousel";

const AnotherBusiness = () => {
  const [shouldRenderEnterprises, setShouldRenderEnterprises] = useState(false);

  return (
    <div>
      <div className="container">
        <div className="row text-center">
          <div className="mx-auto col-md-7">
            <div className="lineup mt-0 mb-2 mx-auto bg-secondary"></div>
            <h3>Outros empreendimentos</h3>
            <p className="">
              Confira algumas outras opções de empreendimentos na região
            </p>
            <button
              className="btn f12 btn-lg d-inline-block btn-outline-info py-2 my-3 mt-3"
              // href="/empreendimentos"
              style={{ width: "140px" }}
              onClick={() => {
                setShouldRenderEnterprises(true);
              }}
            >
              Ver mais
            </button>
          </div>
        </div>
      </div>

      {shouldRenderEnterprises && <EnterpriseCarousel />}
    </div>
  );
};

export default AnotherBusiness;
