const ProjectLogos = {
  JARDIM_ESPLANADA:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/f56ef5388132484b51ce-logo grande.png",
  LA_BELLA_TOSCANA_FIRENZE:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/d2f1c0399d6cfa430b60-logo grande.png",
  CIDADE_JARDIM_GUAIBA:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/fc536a121efd58935b2e-logo grande.png",
  JOY_VOLTA_DA_FIGUEIRA:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/8b42b79584eebd7ff5fc-logo grande.png",
  GRAN_RIVA:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/b31a875f03ee3f7a8412-logo grande.png",
  VILLA_DONA_FRANCISCA_FASE_1:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/b68afc7d0af8bca10317-logo grande.png",
  CASTANHEIRAS:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/2511cc316e3e69b83262-logo grande.png",
  JOY_CANTEGRIL:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/317d82881a0d6c44911c-logo grande.png",
  JARDIM_CRISTINA:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/f8a7b72d9e30870f6d00-logo grande.png",
  LIFE_GARDEN:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/e82df29474c1fc996b3a-logo grande.png",
  PORTO_FELIZ:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/6cd2124201b4c9608e6b-logo grande.png",
  IGUACU:
    "https://s3.us-east-1.amazonaws.com/bairru-corretores/fc2989ef83a212863df0-logo grande.png",
  NAO_CADASTRADO: "",
};

const testimonials = [
  // {
  //   name: "João do Carmo",
  //   enterprise: "Bosque santo Antônio",
  //   description:
  //     "É a primeira vez que negocio com essa empresa, até aqui estou satisfeito com o tratamento.",
  //   logo_enterprise: ProjectLogos.NAO_CADASTRADO,
  // },
  // {
  //   name: "Yriny Pereira",
  //   enterprise: "Campo verde",
  //   description:
  //     "Vizi Lotes me ajudou a conquistar um sonho que jamais imaginei conquistar, empresa séria eu recomendo. Escolheria novamente de olhos fechados.",
  //   logo_enterprise: ProjectLogos.NAO_CADASTRADO,
  // },
  {
    name: "Carlos Gomes",
    enterprise: "Castanheira",
    description: "Muito bom atendimento, transparência nas informações.",
    logo_enterprise: ProjectLogos.CASTANHEIRAS,
  },
  // {
  //   name: "Elvis Soares Amaral",
  //   enterprise: "Cidade do capri",
  //   description:
  //     "Adquiri o terreno por meio da Vizi Lotes onde já se passaram dois anos e sempre fui bem atendido tanto no escritório como via whatsapp. Os colaboradores sempre dispostos a resolverem as exigências cartoriais que aparecem. Todas as minhas petições junto a Vizi foram sempre bem atendidas.",
  //   logo_enterprise: ProjectLogos.NAO_CADASTRADO,
  // },
  {
    name: "Rodrigo Calsavara",
    enterprise: "Gran Riva",
    description:
      "Estamos realizando um grande sonho, que só está sendo possível porque vocês oportunizaram e credibilizaram este acontecimento. Parabéns pelo empreendimento e excelente atendimento!",
    logo_enterprise: ProjectLogos.GRAN_RIVA,
  },
  {
    name: "Adriana Guimares Santos",
    enterprise: "Iguaçu",
    description:
      "Quero agradecer, além da eficiência, rapidez e empatia do corretor, as condições, entrada, as condições das primeiras parcelas, eu jamais conseguiria adquirir algo, gostei muito mesmo. Uma baita oportunidade nunca vista antes. Muito obrigada.",
    logo_enterprise: ProjectLogos.IGUACU,
  },
  {
    name: "Samuel Desrosiers",
    enterprise: "La Bella Toscana",
    logo_enterprise: ProjectLogos.LA_BELLA_TOSCANA_FIRENZE,
    description:
      "Estou muito satisfeito, graças a Vizi Lotes hoje consegui comprar o meu terreno. Indico 100 por 100. Hoje estou feliz por comprar com a Vizi Lotes.",
  },
  // {
  //   name: "Claudionor Santos de Santana",
  //   enterprise: "Jardim dos passaros",
  //   logo_enterprise: ProjectLogos.NAO_CADASTRADO,
  //   description:
  //     "Só tenho que agradecer a Deus pela atenção e confiança em realizar mais um sonho da minha vida.",
  // },
  {
    name: "Jaciara Rodrigues Leite",
    enterprise: "Jardim Esplanada",
    logo_enterprise: ProjectLogos.JARDIM_ESPLANADA,
    description:
      "Agradeço a oportunidade do parcelamento de entrada, isso é importante para compra de uma moradia e pelo atendimento dos corretores, parabéns.",
  },
  //   {
  //     name: "JOSE LUIZ TEIXEIRA",
  //     description:
  //       "Muito bom o empreendimento e o pessoal que nos atendeu! Corretor muito prestativo!",
  //   },
  //   {
  //     name: "SANDRO ROBERTO PAES DE BARROS SOUZA",
  //     description:
  //       "Parabéns pelo método de trabalho de vocês onde possibilita as pessoas a conseguirem fazer com que nossos sonhos sejam os melhores e que sejam realizados. Obrigado.",
  //   },
  //   {
  //     name: "MARIA RODRIGUES",
  //     description:
  //       "Estão de parabéns, estou muito feliz em conseguir o meu lote e com certeza eu recomendo para meus amigos. Muito obrigado, Deus abençoe vocês.",
  //   },
  //   {
  //     name: "ORANDINA DE MELLO CESAR",
  //     description:
  //       "Amei a localização em meio a natureza, muito bom, fui super bem atendida, corretora Janaína tá de parabéns. Muito obrigada a todos.",
  //   },
];

export { testimonials };
