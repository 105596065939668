import React from "react";

const AboutPartnerBanner = () => {
  return (
    <div className="container-fluid">
      <div className="row py-0" style={{ backgroundColor: "#8C461E" }}>
        <div
          className="px-4 py-5 col-12 text-white col-lg-6 col-sm-12"
          style={{
            backgroundColor: "#8C461E",
            borderRadius: "0 0 15px 0",
            marginBottom: "-15px",
          }}
        >
          <div className="row d-flex justify-content-center">
            <div
              className="col-12 col-lg-10 col-sm-12 p-2"
              style={{ zIndex: "29" }}
            >
              <h6 className="f13">Vizi e o loteador</h6>
              <h2 className="pt-0 fbold p-0">
                Uma parceira de&nbsp;
                <br />
                verdade para lançamentos imobiliários horizontais
              </h2>
              <p className="mb-4 p-0 col-11">
                Somos especializados em viabilizar novos lançamentos
                imobiliários, proporcionando um processo de negociação
                transparente, honesto e que possibilita um produto de
                qualidade.&nbsp;
                <br />
                <br />A Vizi Lotes é a melhor solução de capital para
                loteadores. Trabalhamos comprando grandes quantidades de lotes e
                agilizando o fluxo de funding para as obras. Isso permite
                execução mais rápida, gerando economias expressivas para os
                loteadores e impulsionando a valorização dos empreendimentos.
              </p>
            </div>
          </div>
        </div>
        <div
          className="px-2 col-12 col-lg-6 col-sm-12"
          style={{
            backgroundImage: `url("assets/img/meetng.jpg")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
            minHeight: "340px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default AboutPartnerBanner;
