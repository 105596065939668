import React from "react";
import EnterpriseFilters from "../components/Home/EnterpriseFilters";
import BusinessBanner from "../components/Business/BusinessBanner";
import BusinessBannerFooter from "../components/Business/BusinessBannerFooter";
import EnterpriseCarousel from "../components/Home/EnterpriseCarousel";

const Business = () => {
  return (
    <div>
      <EnterpriseFilters />
      <BusinessBanner />
      <EnterpriseCarousel qtde={6} />
      <BusinessBannerFooter />
    </div>
  );
};

export default Business;
