import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../../common/common";

const AboutActionTimeWeb = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="text-center pt-4 pb-0" id="vantagens">
      <div className="container">
        <div className="row my-4 justify-content-center">
          <div className="col-md-6">
            <h6>VANTAGENS</h6>
            <div className="lineup mt-0 mb-2 mx-auto bg-primary"></div>
            <h2 className="text-secondary">
              A Vizi Lotes é a melhor opção para você
            </h2>
            <p>
              Acreditamos que a vida é feita de mudanças. Por isso, a Vizi Lotes
              comercializa loteamentos para que as pessoas possam mudar de
              vida.&nbsp;
              <br />
              <br />
              Nossa missão é fazer com que, independente da classe social, todas
              as pessoas tenham condições de morar bem. Oferecemos loteamentos
              para que todos possam escrever suas histórias.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutActionTimeWeb;
