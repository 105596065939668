import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const createLeadRds = async (body) => {
  try {
    const response = await api.post(`/leads/cria-lead-rds`, body);

    return response.data;
  } catch (error) {
    console.error("Error creating lead rds:", error);
    throw error;
  }
};
