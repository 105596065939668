import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../../common/common";
const AboutInfoMobile = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);
  return (
    <div
      id="sobre"
      className="py-5 text-white border-0 d-block"
      style={{
        backgroundColor: "rgb(140, 70, 30)",
        backgroundImage: `url('assets/img/hero1.png')`,
        backgroundPosition: "right -140px bottom -3px",
        backgroundSize: "130%",
        backgroundRepeat: "no-repeat",
        minHeight: "640px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pl-3 col-12">
            <h6 className="f13">QUEM SOMOS</h6>
            <h2>
              Um ecossistema completo para viabilizar a construção de sonhos
            </h2>
            <p className="mt-3 p-0 mb-0 col-sm-7 col-12">
              Na Vizi Lotes, simplificamos o caminho para que você alcance seu
              desejo de possuir um terreno próprio.&nbsp;
              <br />
              <br />
              Especialistas no assunto, firmamos parcerias com as mais renomadas
              loteadoras do Brasil, realizando uma curadoria das principais
              oportunidades e garantindo os melhores lotes para você.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfoMobile;
