import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../common/common";

const WhoWeAre = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="mt-5 mb-4 px-2" id="sobre">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <p className="d-inline pb-2">QUEM SOMOS</p>
            <div className="lineup mt-2 bg-secondary"></div>
            <h2 className="mt-3 text-primary px-0 col-lg-9 col-11">
              Um ecossistema completo para viabilizar a construção de sonhos
            </h2>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <p>
              Na Vizi Lotes, simplificamos o caminho para que você alcance seu
              desejo de possuir um terreno próprio.&nbsp;
              <br />
              <br />
              Especialistas no assunto, firmamos parcerias com as mais renomadas
              loteadoras do Brasil, realizando uma curadoria das principais
              oportunidades e garantindo os melhores lotes para você.
            </p>
            <a className="btn btn-outline-secondary" href="/sobre">
              Saiba mais
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
