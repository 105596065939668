import React from "react";
import { useMediaQuery } from "react-responsive";
import AboutTeamWeb from "./AboutTeam/AboutTeamWeb.js";
import AboutTeamTablet from "./AboutTeam/AboutTeamTablet.js";

const AboutTeam = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <div>
      {isDesktop && <AboutTeamWeb />}
      {(isTablet || isMobile) && <AboutTeamTablet />}
    </div>
  );
};

export default AboutTeam;
