import React from "react";

const BusinessBannerFooter = () => {
  return (
    <div>
      <div
        className="bg-primary d-none d-sm-block"
        style={{
          backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.8)), url('assets/img/family4.jpg')`,
          backgroundPosition: "top left, bottom right",
          backgroundSize: "100%, cover",
          backgroundRepeat: "repeat, repeat",
          mixBlendMode: "multiply",
        }}
      >
        <div className="container-fluid">
          <div className="row py-0">
            <div
              className="px-2 col-lg-7 col-sm-6"
              style={{ backgroundColor: "rgba(25, 20, 56, 0.8)" }}
            >
              <div className="row">
                <div className="d-none d-lg-block col-md-1"> </div>
                <div
                  className="col-lg-9 py-5 mb-3 ml-2 col-sm-11 col-12"
                  style={{ zIndex: "29" }}
                >
                  <h3 className="pt-3 fbold">
                    A vida é feita de mudanças e a Vizi quer participar da sua
                    próxima mudança
                  </h3>
                  <p className="mb-4">
                    Nascemos da vontade de ajudar a solucionar o grande problema
                    de défict habitacional do Brasil. A falta de financiamento
                    para a compra de lotes fez com que uma parcela da população
                    não pudesse realizar o sonho da casa própria.&nbsp;
                    <br />
                    <br />
                    Nossa linha de crédito facilitada existe porque acreditamos
                    nos desejos de cada um. Sonhe. E conte com a Vizi para
                    realizar.
                  </p>
                  <a
                    className="btn btn-primary fbold f13 btn-lg"
                    href="https://vizilotes.com.br/sobre"
                  >
                    Saiba mais
                  </a>
                </div>
              </div>
            </div>
            <div
              className="px-2 col-lg-5 col-sm-6 col-12"
              style={{ minHeight: "350px" }}
            ></div>
          </div>
        </div>
      </div>

      <div className="bg-primary d-block d-sm-none">
        <div className="container-fluid">
          <div className="row py-0">
            <div
              className="px-2 col-lg-7 col-sm-6"
              style={{ backgroundColor: "rgba(25, 20, 56, 0.8)" }}
            >
              <div className="row">
                <div className="mb-3 ml-2 col-12 py-4 col-md-12 ">
                  <h3 className="pt-3 fbold">
                    A vida é feita de mudanças e a Vizi quer participar da sua
                    próxima mudança
                  </h3>
                  <p className="mb-0">
                    Nascemos da vontade de ajudar a solucionar o grande problema
                    de défict habitacional do Brasil. A falta de financiamento
                    para a compra de lotes fez com que uma parcela da população
                    não pudesse realizar o sonho da casa própria.&nbsp;
                    <br />
                    <br />
                    Nossa linha de crédito facilitada existe porque acreditamos
                    nos desejos de cada um. Sonhe. E conte com a Vizi para
                    realizar.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="px-2 col-lg-5 col-sm-6 col-12"
              style={{
                backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.8)), url('assets/img/family4.jpg')`,
                backgroundPosition: "bottom right",
                backgroundSize: "cover",
                backgroundRepeat: "repeat",
                minHeight: "350px",
              }}
            >
              <a
                className="btn btn-primary fbold f13 btn-lg ml-2"
                href="https://vizilotes.com.br/sobre"
                style={{ marginTop: "18px" }}
              >
                Saiba mais
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessBannerFooter;
