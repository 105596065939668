import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React, { useRef } from "react";

const containerStyle = {
  width: "100%",
  height: "510px",
};

const Maps = ({ coordinates }) => {
  const mapRef = useRef(null);

  const parsedCoordinates = {
    lat: Number(coordinates?.lat || 0),
    lng: Number(coordinates?.long || 0),
  };

  const shouldRenderMap = Boolean(
    parsedCoordinates?.lat && parsedCoordinates?.lng
  );

  return (
    <div className="col-lg-7 pt-3">
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        {shouldRenderMap && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={parsedCoordinates}
            onLoad={(map) => (mapRef.current = map)}
            zoom={15}
          >
            <Marker key={new Date().getTime()} position={parsedCoordinates} />
          </GoogleMap>
        )}
      </LoadScript>
    </div>
  );
};

export default Maps;
