import React from "react";
import { Img } from "../Utils/Img";

const HeaderBanner = ({ enterprise }) => {
  return (
    <div
      className="py-4 text-white page-section"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, .1)), url('${
          enterprise?.images?.banner?.url || "assets/img/gdn1.jpg"
        }')`,
        backgroundPosition: "center, center",
        backgroundSize: "cover, cover",
        backgroundRepeat: "repeat, no-repeat",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="pl-0 col-lg-8 col-md-12 col-12">
            <Img
              className="d-block float-right logo1"
              src={
                enterprise?.images?.logo?.url ||
                "assets/img/vizi-logo-branca.png"
              }
            />
            <h2 className="py-5 col-lg-9 col-sm-7 col-md-6 pb-md-1 col-12 headr1">
              {enterprise?.text ||
                "Infraestrutura completa, conforto, segurança e modernidade para toda a família"}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
