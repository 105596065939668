import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Business from "./pages/Business";
import NotFound from "./pages/NotFound";
import LandPlote from "./pages/LandPlote";
import About from "./pages/About";
import Faq from "./pages/Faq";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/empreendimentos", element: <Business /> },
    { path: "/sobre", element: <About /> },
    { path: "/lote", element: <LandPlote /> },
    { path: "/faq", element: <Faq /> },
    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
