import React from "react";
import { Img } from "../../Utils/Img";

const EnterpriseTabletItem = ({ enterprises = [] }) => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="row">
          {enterprises.map((item, index) =>
            item ? (
              <div key={index} className="col-12 mx-auto col-lg-6 mb-3">
                <div
                  className="card border15 bg-dark"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.1) 62%, rgba(0, 0, 0, 0.7) 62%), url("${
                      item.banner?.url || "assets/img/pic01.jpg"
                    }")`,
                    backgroundPosition: "center",
                    backgroundSize: "100%, cover",
                    backgroundRepeat: "no-repeat",
                    height: "300px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div className="card-body">
                    <div className="row my-2">
                      <div className="pl-3 pr-0 col-lg-12">
                        <Img
                          className="img-fluid d-block float-right"
                          src={item.logo?.url || "assets/img/logo-empreend.png"}
                          alt={item.nome_empreendimento || "Image"}
                          style={{
                            maxWidth: "100px",
                            maxHeight: "70px",
                            width: "auto",
                            height: "auto",
                          }}
                        />
                        <h6 className="fbold text-uppercase d-inline-block">
                          {item.nome_empreendimento || "-"}
                        </h6>
                        <p className="mb-0 f10">
                          {item.endereco?.cidade || "-"}
                        </p>
                        <p className="f10 mb-4">
                          {`Lotes a partir de ${item.area_min || "-"}` || "-"}
                        </p>
                        <div className="row">
                          <div className="col-md-12 d-flex flex-column justify-content-start align-items-start">
                            <div className="card-status2 mb-1">
                              {item.endereco.uf || "-"}
                            </div>
                            <div className="card-status2 mb-1">
                              {item.tipo || "-"}
                            </div>
                            <div className="card-status2">
                              {item.status_empreendimento || "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end col-md-12">
                        <a
                          className="btn btn-secondary fbold f13"
                          href={"/lote?id=" + item.id_empreendimento}
                        >
                          Conheça
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default EnterpriseTabletItem;
