import React, { useEffect, useState } from "react";
import { fetchAll } from "../../services/faqs";

const FaqQuestions = () => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const data = await fetchAll();
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchQuestions();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row my-4">
          <div className="mx-auto col-lg-9">
            <div id="faqs" className="accordion">
              {questions.map((question, index) => (
                <div className="card mb-2 border15" key={index}>
                  <div
                    id={`heading${index}`}
                    className="card-header bgd3 border15"
                  >
                    <h6 className="mb-0">
                      <a
                        href="#"
                        data-toggle="collapse"
                        data-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                        className="nav-link text-dark collapsible-link collapsed"
                      >
                        {question.question}
                      </a>
                    </h6>
                  </div>
                  <div
                    id={`collapse${index}`}
                    aria-labelledby={`heading${index}`}
                    className="collapse"
                  >
                    <div className="card-body p-3">
                      <p className="m-0">{question.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqQuestions;
