import React from "react";
import { Img } from "../Utils/Img";

const SliderCarousel = () => {
  return (
    <div className="mb-3">
      <div className="container-fluid">
        <div className="row">
          <div className="text-center col-lg-12 col-12 mx-0 px-0">
            <div
              id="carousel1"
              className="carousel slide carousel-fade"
              data-ride="carousel"
              data-interval="5000"
            >
              <div className="carousel-inner">
                <div className="carousel-item">
                  {" "}
                  <Img className="d-block w-100" src="assets/img/1-ComplexoLaBellaToscanaFirenzeeSienaSJP.jpg" />
                  <div className="carousel-caption">
                    <p className="mb-1">São José dos Pinhais - PR</p>
                    <h2>Complexo La Bella Toscana</h2>
                    <p className="mx-auto col-lg-8">
                    O La Bella Toscana é um complexo com três Condomínios em São José dos Pinhais. Nossa inspiração é a mesma que inspirou os grandes artistas renascentistas: a beleza da paisagem, a arquitetura requintada e o estilo de vida que celebra o prazer de viver. Em cada traço arquitetônico, você encontrará a essência da Toscana. Aqui, a vida é como um bom vinho toscano: rica em experiências.
                    </p>
                    <a className="btn btn-outline-light" href="lote?id=1277f37f-19bd-457e-b978-5cee8000d1ab">
                      Conheça
                    </a>
                  </div>
                </div>
                <div className="carousel-item active">
                  {" "}
                  <Img className="d-block w-100" src="assets/img/1-CondominioJoyCantegrilViamao.jpg" />
                  <div className="carousel-caption">
                    <p className="mb-1">Viamão - RS</p>
                    <h2>Joy Cantegril</h2>
                    <p className="mx-auto col-lg-8">
                    O Joy Cantegril foi projetado para oferecer o melhor do ambiente urbano em um local privilegiado pela natureza, ao lado de uma área de preservação que mantém você conectado com o verde. Como proprietário, você será presenteado com um título do novo cantegril Clube, vinculado à unidade adquirida, podendo utilizar a estrutura do clube como associado. Além disso, o condomínio te oferecerá itens exclusivos de lazer e conforto, próximos ao seu lar. O Cantegril conta com lotes de 250 a 480m².
                    </p>
                    <a className="btn btn-outline-light" href="lote?id=90231cd8-c5ca-4eba-8d82-2202b06dd38e">
                      Conheça
                    </a>
                  </div>
                </div>
              </div>
              <ol className="carousel-indicators">
                <li data-target="#carousel1" data-slide-to="0" className="">
                  {" "}
                </li>
                <li
                  data-target="#carousel1"
                  data-slide-to="1"
                  className="active"
                >
                  {" "}
                </li>
              </ol>
              <a
                className="carousel-control-prev"
                href="#carousel1"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
              </a>
              <a
                className="carousel-control-next"
                href="#carousel1"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCarousel;
