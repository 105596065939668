import React from "react";

const LawText = ({ enterprise }) => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="row text-center">
          <div className="mx-auto col-md-7">
            <div className="lineup mt-0 mb-2 mx-auto bg-secondary"></div>
            <h3>Texto legal</h3>
            <p className="text-muted">{enterprise.texto_legal}. </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawText;
