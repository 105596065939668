import React from "react";
import { Img } from "../Utils/Img";

const LandDescription = ({ enterprise }) => {
  const arredondarParaCima = Math.ceil(enterprise.parcela_minima);
  const parcela = arredondarParaCima.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className="pt-lg-5 pb-1 pt-md-0 page-section" id="toptitle">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-5">
            <h5 className="fbold">{enterprise.nome}</h5>
            {/* <p className="mb-2">{enterprise.texto_legal}</p> */}
            <p className="mb-2">
              Na Vizi, acreditamos que cada lote é uma oportunidade única de
              construir o seu futuro. Nossos empreendimentos são cuidadosamente
              planejados para oferecer o melhor em infraestrutura, localização e
              qualidade de vida. Situados em áreas privilegiadas, nossos lotes
              são ideais para a construção da casa dos seus sonhos ou para um
              investimento seguro e rentável.
            </p>
            <div className="row pb-3">
              <div className="tags1 pr-0 pt-2 col-md-12 col-sm-12 align-items-start col-lg-5">
                <div
                  className="px-1 py-1 border15 bg-secondary d-inline-block mr-1 mb-1"
                  style={{ minWidth: "142px" }}
                >
                  <h6 className="mb-0 p-1 f13">
                    {enterprise.status_empreendimento}
                  </h6>
                </div>
                <div className="px-1 py-1 border15 bg-secondary  mr-1 d-inline-block mb-1">
                  <h6 className="mb-0 p-1 f13">{enterprise.tipo_loteamento}</h6>
                </div>
              </div>
              <div
                className="tags1 pt-2 col-md-12 col-lg-6 d-flex"
                style={{ gap: "20px" }}
              >
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <div className="d-inline-block">
                    <Img
                      src="assets/img/pin1.svg"
                      width="36px"
                      className="d-inline-block"
                    />
                  </div>
                  <div className="d-block">
                    <p className="mb-0 ml-1 text-info f11">
                      {enterprise.endereco?.estado}
                    </p>
                    <h5 className="mb-0 mt-0 ml-1 text-info">
                      {enterprise.endereco?.cidade}
                    </h5>
                  </div>
                </div>
                <div className="mr-2 d-flex justify-content-start align-items-center mb-1">
                  <div className="d-inline-block">
                    <Img
                      src="assets/img/crop1.svg"
                      width="36px"
                      className="d-inline-block"
                    />
                  </div>
                  <div className="d-block">
                    <p className="mb-0 ml-1 text-info f11">Lotes a partir de</p>
                    <h5 className="mb-0 mt-0 ml-1 text-info">
                      {enterprise.lote_minimo}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-7 flex-column d-flex justify-content-end">
            <div className="row">
              <div className="align-items-center d-flex justify-content-end pr-1 tags2 col-lg-12 col-7 w-full text-center">
                <h6 className="px-2 py-2 d-inline-block border border15 text-info my-1">
                  Entrada facilitada em até 180x
                </h6>
              </div>
              <div className="d-flex justify-content-end pr-1 tags2 align-items-center col-lg-12 col-5">
                <p className="mb-0 pr-1 text-info f11">
                  Parcelas a partir de&nbsp;
                </p>
                <div className="px-2 py-2 px-md-2 d-inline-block border15 bg-secondary d-inline-flex justify-content-end align-items-center">
                  <p className="f10 d-inline-block mb-0">R$</p>
                  <h3 className="d-inline-block fbold mb-0">{parcela}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandDescription;
