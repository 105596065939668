import React, { useEffect, useState } from "react";
import { Img } from "../Utils/Img";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TestimonialItem from "./TestimonialItem/TestimonialItem";

const TestemonialCarousel = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const state = {
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      575: {
        items: 1,
      },
      768: {
        items: 1,
      },
      991: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <OwlCarousel
      key={windowWidth} // Isso força a re-renderização ao mudar o tamanho da janela
      className="owl-carousel owl-theme mt-5 mb-10 owl-loaded owl-drag"
      loop={true}
      autoplay={true}
      autoplayTimeout={4000}
      autoplayHoverPause={false}
      items={3}
      stagePadding={20}
      center={true}
      margin={80}
      dots={true}
      responsive={state.responsive}
      onChange={(item, item2) => {}}
    >
      <TestimonialItem />
    </OwlCarousel>
  );
};

export default TestemonialCarousel;
