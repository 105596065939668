import React from "react";
import { useMediaQuery } from "react-responsive";

import WebComponent from "./EnterpriseWebItem";
import TabletComponent from "./EnterpriseTabletItem";
import EnterpriseMobileItem from "./EnterpriseMobileItem";

const EnterpriseCarouselItem = ({ enterprises }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <div>
      {isDesktop && <WebComponent enterprises={enterprises} />}
      {isTablet && <TabletComponent enterprises={enterprises} />}
      {isMobile && <EnterpriseMobileItem enterprises={enterprises} />}
    </div>
  );
};

export default EnterpriseCarouselItem;
