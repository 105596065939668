import React, { useContext } from "react";
import { FilterContext } from "../../../context/filterContext";

const EnterpriseMobileItem = () => {
  const { filter, setFilter } = useContext(FilterContext);

  function clearPagination() {
    if (filter.pagination) {
      filter.pagination.page = 1;
      filter.pagination.limit = 4;
    }
  }

  const handleCityChange = (city) => {
    clearPagination();
    // setFilter({ ...filter, city });
  };

  const handleStateChange = (state) => {
    clearPagination();
    // setFilter({ ...filter, state });
  };

  const handleStatusChange = (status) => {
    clearPagination();
    // setFilter({ ...filter, status });
  };

  const cities = [
    { id: 1, name: "Cidade" },
    { id: 2, name: "São José dos Pinhais" },
    { id: 3, name: "Curitiba" },
  ];

  const states = ["Estado", "SP", "PR", "SC", "RS"];

  const statuses = [
    "Status",
    "Pré-lançamento",
    "Lançamento",
    "Em obra",
    "Pronto para morar",
    "Pronto para construir",
  ];

  return (
    <div className="d-sm-block d-md-none" style={{ width: "100%" }}>
      <div className="container-fluid d-flex justify-content-center flex-column mx-0">
        <div id="filter-small" className="row py-1">
          <div className="col-lg-12 mt-1 d-flex justify-content-between align-items-center">
            <select
              className="form-control d-inline-block border-d f13 rox px-2 mr-2 mb-0"
              id="Cidades"
              style={{ marginTop: "-2px" }}
              onChange={(e) => handleCityChange(e.target.value)}
              value={filter.city}
            >
              {cities.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>

            <select
              className="form-control d-inline-block border-d f13 rox px-2 mr-2 mb-0"
              id="Estados"
              style={{ marginTop: "-2px" }}
              onChange={(e) => handleStateChange(e.target.value)}
              value={filter.state}
            >
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>

            <select
              className="form-control d-inline-block border-d f13 rox px-2 mr-2 mb-0"
              id="Status"
              style={{ marginTop: "-2px" }}
              onChange={(e) => handleStatusChange(e.target.value)}
              value={filter.status}
            >
              {statuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseMobileItem;
