/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Img } from "../../Utils/Img";
const AboutBenefitsTabs = () => {
  return (
    <div className="pt-2 pb-5">
      <div className="container">
        <div
          className="row mb-1 px-1 px-md-3 d-flex flex-column justify-content-around align-items-start"
          style={{ borderBottom: "2px solid #d7d7d7" }}
        >
          <ul
            className="nav nav-tabs nav-ills d-flex justify-content-around border-0 text-body flex-row align-items-center"
            style={{ width: "100%" }}
          >
            <li className="nav-item active">
              <a
                href="#"
                className="nav-link pb-2 mn3 active show"
                data-toggle="tab"
                data-target="#tab1"
              >
                Programa de <br className="d-sm-none" />
                Vantagens
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link pb-2 mn3"
                data-toggle="tab"
                data-target="#tab2"
                href=""
              >
                Aplicativo <br className="d-sm-none" /> BairrU
              </a>
            </li>
            <li className="nav-item">
              {" "}
              <a
                className="nav-link pb-2 mn3"
                data-toggle="tab"
                data-target="#tab3"
                href=""
              >
                Financiamento <br className="d-sm-none" />
                Próprio
              </a>{" "}
            </li>
            <li className="nav-item">
              {" "}
              <a
                href=""
                className="nav-link pb-2"
                data-toggle="tab"
                data-target="#tab4"
              >
                Atendimento <br className="d-sm-none" />
                Personalizado
              </a>{" "}
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade active show" id="tab1" role="tabpanel">
            <div className="row mb-1 px-3 h-video">
              <div className="p-3 col-lg-5">
                <h5>
                  <strong>
                    Programa de Vantagens Nosso Programa de Vantagens premia
                    anualmente os clientes que mantêm a pontualidade de
                    pagamento.
                  </strong>
                </h5>
                <p className="">
                  Criado em 2020, o Programa de Vantagens é uma funcionalidade
                  exclusiva para os clientes BairrU e Vizi, que proporciona uma
                  pontuação baseada em ações contratuais ou de interação com a
                  marca, como a antecipação de parcelas, o pagamento em dia e a
                  manutenção de um cadastro ativo e atualizado.&nbsp;
                  <br />
                  <br />
                  Como resultado, esta pontuação resulta em categorias
                  específicas, que recebem prêmios conforme sua colocação.
                </p>
              </div>
              <div className="p-3 col-lg-7">
                <iframe
                  src="https://www.youtube.com/embed/vENtMcMIYEg"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video"
                  style={{
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="tab2" role="tabpanel">
            <div className="row mb-1 px-3">
              <div className="p-3 col-lg-5">
                <h5>
                  <strong>
                    Possuímos um aplicativo pensado para poupar seu tempo, com
                    diversas funcionalidades.
                  </strong>
                </h5>
                <p>
                  Nosso time de tecnologia opera de ponta a ponta a gestão do
                  relacionamento com você, permitindo uma comodidade extra: um
                  aplicativo com todas as funcionalidades necessárias para você
                  conferir a gestão do seu contrato de forma digital, desde
                  acompanhamento de score, segunda via de boletos e FAQ sobre o
                  produto ou sobre o contrato.
                </p>
              </div>
              <div className="p-3 col-lg-7">
                <Img
                  className="img-fluid d-block mx-auto"
                  src="assets/img/bairru.png"
                  width="600px" // a imagem original é muito pequena
                />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="tab3" role="tabpanel">
            <div className="row mb-1 px-3">
              <div className="p-3 col-lg-5">
                <h5>
                  <strong>
                    Financiamos sua compra por meio de uma linha de crédito
                    facilitada com incentivo aos bons pagadores.
                  </strong>
                </h5>
                <p>
                  Por ter um financiamento próprio, isso nos permite facilitar a
                  aquisição de lotes e viabilizar a construção de seu novo lar
                  ou proporcionar um novo investimento. Isso se exemplifica pela
                  comodidade de possibilitar uma entrada parcelada, um
                  financiamento em condições diversas e um prazo de até 180
                  meses. <br />
                  <br /> Tudo isso com uma análise de crédito própria e uma
                  condicional específica de aprovação, alimentada via
                  inteligência artificial.{" "}
                </p>
              </div>
              <div className="p-3 col-lg-7">
                <Img
                  className="img-fluid d-block mx-auto"
                  src="assets/img/group_1.png"
                />
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="tab4" role="tabpanel">
            <div className="row mb-1 px-3">
              <div className="p-3 col-lg-5">
                <h5>
                  <strong>
                    Possuímos uma equipe de atendimento ao cliente dedicada a
                    ajudar nossos clientes.
                  </strong>
                </h5>
                <p>
                  Nosso time de atendimento é especializado no nosso produto,
                  bem como recebe treinamentos e capacitações constantemente,
                  visando aprimorar tanto seus conhecimentos sobre os
                  empreendimentos da companhia, quando os processos
                  administrativos e, claro, buscando sempre o sucesso de nosso
                  cliente, sua satisfação e a resolução de problemas de forma
                  plena e efetiva.{" "}
                </p>
              </div>
              <div className="p-3 col-lg-7">
                <Img
                  className="img-fluid d-block mx-auto"
                  src="assets/img/group_2.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBenefitsTabs;
