import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../common/common";

const Testimonials = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="row mt-5 mb-0" id="depoimentos">
      <div className="col-md-12 text-center mt-4">
        <div className="lineup mt-0 mb-2 mx-auto bg-primary"></div>
        <h2 className="mb-2">Depoimentos</h2>
        <p className="col-lg-5 col-10 col-sm-7 mx-auto mb-2">
          Confira os relatos de quem já contou com a ajuda da Vizi para realizar
          sonhos! Separamos alguns depoimentos de compradores satisfeitos, que
          adquiriram lotes para morar, investir ou montar seu negócio em
          parceria com a Vizi Lotes:
        </p>
      </div>
    </div>
  );
};

export default Testimonials;
