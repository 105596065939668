import React, { useEffect } from "react";
import { Img } from "../Utils/Img";
import { scrollToHashOnPageLoad } from "../../common/common";

const Benefits = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="p-2" id="vantagens">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 col-md-6 pb-5">
            <p>VANTAGENS</p>
            <div className="lineup mt-2 bg-secondary"></div>
            <h2 className="mt-3 text-primary px-0 col-lg-9 col-11">
              Comprar com a Vizi é simples, seguro e prático!
            </h2>
            <a
              className="btn btn-outline-secondary my-1"
              href="/sobre#vantagens"
            >
              Saiba mais
            </a>
          </div>
          <div className="col-lg-6 px-0 col-md-6">
            <div className="row">
              <div className="col-lg-3 col-md-6 pb-4 d-flex flex-column align-items-center justify-content-start col-6">
                <div className="card rec01">
                  <div className="card-body text-center">
                    <Img
                      className="img-fluid d-block mx-auto mb-2"
                      src="assets/img/ic-dollar.svg"
                      width="50px"
                    />
                    <p className="card-text f11 fbold">Financiamento Próprio</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center justify-content-start col-6">
                <div className="card rec01">
                  <div className="card-body text-center">
                    <Img
                      className="d-block mx-auto mb-2 img-fluid"
                      src="assets/img/ic-mob.svg"
                      width="50px"
                    />
                    <p className="card-text f11 fbold">
                      Aplicativo <br /> Bairru
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center justify-content-start col-6">
                <div className="card rec01">
                  <div className="card-body text-center">
                    <Img
                      className="img-fluid d-block mx-auto mb-2"
                      src="assets/img/ic-star.svg"
                      width="50px"
                    />
                    <p className="card-text f11 fbold">Programa de Vantagens</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center justify-content-start col-6">
                <div className="card rec01">
                  <div className="card-body text-center">
                    <Img
                      className="img-fluid d-block mx-auto mb-2"
                      src="assets/img/ic-user.svg"
                      width="50px"
                    />
                    <p className="card-text f11 fbold">
                      Atendimento Personalizado
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
