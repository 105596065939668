import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../../common/common";
import { Img } from "../../Utils/Img";

const benefits = [
  {
    title: "Autonomia e Simplicidade",
    description:
      "O loteador terá total autonomia para executar seu projeto, bem como uma gestão de parceria simples e descomplicada.",
    imgSrc: "assets/img/gr1.svg",
    imgWidth: "52px",
  },
  {
    title: "Garantia de Execução",
    description:
      "A Vizi Lotes investe 100% do custo de obra previsto, garantindo assim a plena execução do projeto até a entrega das unidades.",
    imgSrc: "assets/img/gr2.svg",
    imgWidth: "50px",
  },
  {
    title: "Sem Dívida e sem Sociedade",
    description:
      "A Vizi não entra como sócia da empresa e também não emite dívida, simplificando o negócio para uma simples compra e venda de lotes, garantindo que o loteador mantenha total autonomia sob gestão do seu patrimônio.",
    imgSrc: "assets/img/gr3.svg",
    imgWidth: "52px",
  },
  {
    title: "Sem Demandar Performance Comercial",
    description:
      "Nosso investimento utiliza como pagamento o recebimento de lotes, permitindo que a liberação de verba independa da performance comercial do empreendimento.",
    imgSrc: "assets/img/gr3.svg",
    imgWidth: "52px",
  },
];

const AboutPartnerBenefitsWeb = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="py-5 bg-secondary d-none d-xl-block" id="vantagens">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6">
            <h2 className="p-0">Vantagens para o loteador</h2>
            <p className="mb-4 pb-3">
              A Vizi é um Ecossistema de crédito que conecta Investidores,
              Loteadores e Clientes através de um modelo inovador que alia
              tecnologia com experiência e gestão local, potencializando de
              forma sustentável a criação de valor para toda rede,
              proporcionando vantagens tangíveis aos nossos parceiros
              comerciais.
            </p>
          </div>
          <div className="col-lg-6"></div>
        </div>
        <div className="row">
          {benefits.map((benefit, index) => (
            <div key={index} className="col-lg-6 mb-4">
              <div className="d-flex align-items-start">
                <Img
                  className="img-fluid mr-3"
                  src={benefit.imgSrc}
                  width={benefit.imgWidth}
                />
                <div>
                  <h5 className="mb-1 fbold">{benefit.title}</h5>
                  <p>{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutPartnerBenefitsWeb;
