import React from "react";
import FaqBanner from "../components/Faq/FaqBanner";
import FaqQuestions from "../components/Faq/FaqQuestions";
import FaqContact from "../components/Faq/FaqContact";

const Faq = () => {
  return (
    <div>
      <FaqBanner />
      <FaqQuestions />
      <FaqContact />
    </div>
  );
};

export default Faq;
