import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchAll = async () => {
  try {
    const response = await api.get('/faq');

    return response.data;
  } catch (error) {
    console.error("Error fetch faq:", error);
    throw error;
  }
};
