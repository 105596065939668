import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../../common/common";
const AboutInfoWeb = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div
      id="sobre"
      className="py-5 text-white border-0 d-none d-xl-block"
      style={{
        backgroundColor: "#8c461E",
        backgroundImage: `url('assets/img/hero0.png')`,
        backgroundPosition: "right -300px bottom -3px",
        backgroundSize: "83%",
        backgroundRepeat: "no-repeat",
        minHeight: "400px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-10 pl-3">
            <h6 className="f13">QUEM SOMOS</h6>
            <h2>
              Um ecossistema completo para viabilizar a construção de sonhos
            </h2>
            <p className="col-8 mt-3 p-0 mb-0">
              Na Vizi Lotes, simplificamos o caminho para que você alcance seu
              desejo de possuir um terreno próprio.&nbsp;
              <br />
              <br />
              Especialistas no assunto, firmamos parcerias com as mais renomadas
              loteadoras do Brasil, realizando uma curadoria das principais
              oportunidades e garantindo os melhores lotes para você.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfoWeb;
