import React from "react";
import { useMediaQuery } from "react-responsive";
import LandGalleryWeb from "./Gallery/LandGalleryWeb";
import LandGalleryTablet from "./Gallery/LandGalleryTablet";

const LandGallery = (enterprise) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const images = enterprise?.enterprise?.images?.image;
  const imagesImplantacao = enterprise?.enterprise?.images?.implantacao;

  return (
    <div>
      {isDesktop && (
        <LandGalleryWeb images={images} implantacao={imagesImplantacao} />
      )}
      {(isTablet || isMobile) && (
        <LandGalleryTablet images={images} implantacao={imagesImplantacao} />
      )}
    </div>
  );
};

export default LandGallery;
