import React from "react";
import AboutBenefitsText from "./AboutBenefits/AboutBenefitsText";
import AboutBenefitsTabs from "./AboutBenefits/AboutBenefitsTabs";

const AboutBenefits = () => {
  return (
    <div>
      <AboutBenefitsText />
      <AboutBenefitsTabs />
    </div>
  );
};

export default AboutBenefits;
