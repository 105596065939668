export function scrollToSection(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export function scrollToHashOnPageLoad() {
  const url = window.location.href;
  const hashIndex = url.indexOf("#");

  if (hashIndex !== -1) {
    const id = url.substring(hashIndex + 1);
    scrollToSection(id);
  }
}

export function checkCurrentPath(targetPath) {
  const currentPath = window.location.pathname;
  return currentPath === targetPath;
}
