import React from "react";

const Arround = ({ enterprise }) => {
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const chunkedItems = chunkArray(enterprise?.perto_de || [], 3);

  const formatText = (text) => {
    return text
      .split(".")
      .map(
        (sentence) =>
          sentence.trim().charAt(0).toUpperCase() + sentence.slice(1)
      )
      .join(". ");
  };

  const parsedCoordinates = {
    lat: Number(enterprise.coordinates?.lat || 0),
    lng: Number(enterprise.coordinates?.long || 0),
  };

  const shouldRenderMap = Boolean(
    parsedCoordinates.lat && parsedCoordinates.lng
  );

  return (
    <div className="col-lg-5 page-section" id="localizacao">
      <h3 className="pt-3 fbold text-primary">
        Veja o que&nbsp;
        <br />
        você terá por perto
      </h3>
      <p>
        {formatText(
          `No ${enterprise.nome} você terá diversas facilidades a poucos minutos de casa! Confira o que tem na região:`
        )}
      </p>
      {chunkedItems.map((chunk, index) => (
        <div key={index} className="row">
          <div className="d-flex align-items-center px-0 col-lg-12">
            {chunk.map((local) => (
              <div
                key={local.id}
                className="d-inline-block border15 m-1 icons2"
              >
                <div className="d-flex align-items-center">
                  <p className="mb-0 mt-1 mr-2">
                    {local.label.split(".")[0].toUpperCase()}
                  </p>
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "22px", // Tamanho do ícone
                      color: "#4B40C5", // Cor do ícone
                      fontWeight: 300, // Peso da fonte
                      fontVariationSettings: '"GRAD" 0, "OPSZ" 40', // Configurações de variação da fonte (corrigido)
                    }}
                  >
                    {local.icon}
                  </span>
                </div>
                <p className="mb-0 mt-1">{local.label.split(".")[1]}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="d-flex align-items-center px-0 justify-content-around col-lg-12">
        {true && (
          <>
            <a
              className="btn btn-light btn-block mr-2 ml-1 f13"
              href={`https://maps.google.com/?q=${enterprise?.coordinates?.lat},${enterprise?.coordinates?.long}`}
              target="_blank"
              rel="noreferrer"
            >
              Abrir no Google Maps
            </a>
            <a
              className="btn btn-light btn-block ml-2 f13"
              href={`https://www.waze.com/ul?ll=${enterprise?.coordinates?.lat},${enterprise?.coordinates?.long}&navigate=yes`}
              target="_blank"
              rel="noreferrer"
            >
              Abrir no Waze
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default Arround;
