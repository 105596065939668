import React from "react";

const AboutInfoBanner = () => {
  return (
    <div className="container-fluid">
      <div className="row py-0">
        <div
          className="px-2 col-12 col-lg-5 order-2 order-sm-1 col-sm-6"
          style={{
            minHeight: "350px",
            backgroundImage: `url(assets/img/fam5.jpg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></div>
        <div className="px-2 col-lg-7 order-1 order-md-2 col-12 col-sm-6">
          <div className="row d-flex justify-content-center">
            <div
              className="p-lg-5 col-sm-12 col-12 col-lg-10 order-1 order-sm-2 p-sm-4 p-4"
              style={{ zIndex: "29" }}
            >
              <h2 className="pt-3 fbold p-0 col-8">
                Nossa vida é uma constante mudança.
              </h2>
              <p className="mb-4 p-0 col-11">
                Mudamos de opinião quando vemos que estamos errados. Mudamos de
                trabalho quando temos uma nova oportunidade. Mudamos de vida
                quando queremos trilhar um novo caminho. Aliás, mudar de vida
                pode ser transformador. Afinal, não se trata apenas do local
                onde se escolhe morar.&nbsp;
                <br />
                <br />
                Mas, também, o estilo de vida que se escolhe ter. Uma vida mais
                sustentável quando nos aproximamos do meio ambiente. Uma vida
                mais divertida quando buscamos mais lazer no dia a dia. Uma vida
                mais independente quando decidimos ter a casa própria.&nbsp;
                <br />
                <br />
                Porém, muita gente acha que mudar de vida é difícil. De fato, às
                vezes é. Mas quando se tem alguém que lhe dê suporte, auxílio e
                segurança nada é impossível.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfoBanner;
