import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import Routes from "./Routes";

//require("dotenv").config();

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
