import { Img } from "../../Utils/Img";
import React, { useEffect } from "react";
import { scrollToHashOnPageLoad } from "../../../common/common";
const AboutPartnerBenefitsTablet = () => {
  useEffect(() => {
    scrollToHashOnPageLoad();
  }, []);

  return (
    <div className="py-5 bg-secondary d-xl-none" id="vantagens">
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-6 p-2">
            <h2 className="p-0 col-7">Vantagens para o loteador</h2>
          </div>
          <div className="col-md-6 d-flex align-items-center p-2">
            <p>
              A Vizi é um Ecossistema de crédito que conecta Investidores,
              Loteadores e Clientes através de um modelo inovador que alia
              tecnologia com experiência e gestão local, potencializando de
              forma sustentável a criação de valor para toda rede,
              proporcionando vantagens tangíveis aos nossos parceiros
              comerciais.{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 p-2 py-0 px-2 col-12">
            <div className="line-left2 d-inline d-none d-xl-none float-left pr-2">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-2 pb-3 mb-md-3"
              src="assets/img/gr1.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Autonomia e Simplicidade</h6>
            <p>
              O loteador terá total autonomia para executar seu projeto, bem
              como uma gestão de parceria simples e descomplicada.
            </p>
          </div>
          <div className="p-2 col-md-6 col-12">
            <div className="line-left2 d-inline d-none d-xl-none float-left pr-2">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-3 pb-2 mb-md-3"
              src="assets/img/gr2.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Garantia de Execução</h6>
            <p className="f12">
              A Vizi Lotes investe 100% do custo de obra previsto, garantindo
              assim a plena execução do projeto até a entrega das unidades.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 p-2 py-0 px-2 col-12">
            <div className="line-left2 d-inline d-none d-xl-none float-left pr-2">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-4 pb-3 mb-md-3 mb-sm-4 pb-sm-5"
              src="assets/img/gr3.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Sem Dívida e sem Sociedade</h6>
            <p>
              A Vizi não entra como sócia da empresa e também não emite dívida,
              simplificando o negócio para uma simples compra e venda de lotes,
              garantindo que o loteador mantenha total autonomia sob gestão do
              seu patrimônio.
            </p>
          </div>
          <div className="p-2 col-md-6 col-12">
            <div className="line-left2 d-inline d-none d-xl-none float-left pr-2">
              {" "}
            </div>
            <Img
              className="img-fluid d-block float-left mr-2 mb-3 pb-4 mb-md-3"
              src="assets/img/gr4.svg"
              width="44px"
            />
            <h6 className="mb-1 fbold">Sem Demandar Performance Comercial</h6>
            <p className="f12">
              Nosso investimento utiliza como pagamento o recebimento de lotes,
              permitindo que a liberação de verba independa da performance
              comercial do empreendimento.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPartnerBenefitsTablet;
